import React from 'react'
import { LOCAL_DATA } from '../../config/site-content';
import { skylabApi } from '../../config/skylab';
import { IServerData } from '../../interface/data';
import { Link } from 'gatsby';
import { Icon } from '../../config/icons';
interface IMobileNav {
   serverData?: IServerData
}

const MobileNav = ({ serverData }: IMobileNav) => {
   const menu = (serverData && serverData.navMenus.length > 0 ? serverData.navMenus : LOCAL_DATA.siteMenu) as string[];
   const updatedMenu = [...menu];
   const pageIndex = updatedMenu.indexOf('Paginas');
   const servicosIndex = updatedMenu.indexOf('Serviços');
   if (pageIndex !== -1 && servicosIndex !== -1) {
      updatedMenu.splice(servicosIndex, 0, updatedMenu.splice(pageIndex, 1)[0]);
   }
   console.log(updatedMenu);

   const icons = [
      <Icon.home className='text-primmary' />,
      <Icon.layout className='text-primmary' />,
      <Icon.close className='text-primmary' />,
      <Icon.network className='text-primmary' />,
      <Icon.messageChat className='text-primmary' />
   ];

   return (
      <div className="fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bg-white shadow-lg border border-gray-200 bottom-0 left-1/2 block md:hidden">
         <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
            {updatedMenu.map((item, index) => (
               <Link to={`${index === 0 ? '/' : '/' + item.toLowerCase()}`} className={`inline-flex flex-col items-center justify-center px-5 group ${index === 2 ? 'border-b-4 border-primmary' : 'hover:bg-gray-50'}`} key={index}>
                  {icons[index]}
                  <b className="text-sm">{item}</b>
               </Link>
            ))}
         </div>
      </div>
   )
}

export default MobileNav
export async function getServerData() {
   try {
      const res = await fetch(skylabApi)


      if (!res.ok) {
         throw new Error(`Response failed with status ${res.status}`);
      }

      return {
         props: await res.json(),
      }
   } catch (error) {
      return {
         notFound: true,
      };
   }
}