import React, { ReactNode } from 'react'
import { primary } from '../../config/site-content'
import { useMain } from '../../context/provider'
interface MyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
}

const Button: React.FC<MyButtonProps> = ({ children, ...props }) => {
  const { handleClick } = useMain()
  return (
    <button {...props} onClick={handleClick} className={`rounded-md p-2 font-semibold bg-primmary hover:bg-orange-600 text-white`} style={{ background: primary ? primary : '' }}>
      {children}
    </button>
  )
}

export default Button